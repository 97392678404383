import * as React from 'react'
import { Box, Divider, Link, Text, Table, Tbody, Tr, Td } from '@chakra-ui/react'
import { footerTable, footerWrapper } from './footer.module.css'

const FooterWithCredit = () => {
  return (
    <Box mt='20' className={footerWrapper}>
      <Divider pt='200px' />
      <Box py='10' align='center'>
        <Table size='sm' fontSize='sm' variant='unstyled' mb='10' maxW='350px' textAlign='left' className={footerTable}>
          <Tbody>
            <Tr>
              <Td><Text>サイト作成・写真・文</Text></Td>
              <Td>
                ちかく{' '}
                <Link href="https://twitter.com/ooooooooo/" target='_blank'>
                  @ooooooooo
                </Link>
              </Td>
            </Tr>
            <Tr>
              <Td><Text>Twitter</Text></Td>
              <Td>
                野良サイン{' '}
                <Link href="https://twitter.com/norasign/" target='_blank'>
                  @norasign
                </Link>
              </Td>
            </Tr>
            <Tr>
              <Td><Text>お問い合わせ</Text></Td>
              <Td>
                <Link href="https://docs.google.com/forms/d/e/1FAIpQLSfm-yk40wzdf7NeCKgWMWL2mx2DhgQGiMKgdg8_1FXv7bPGUA/viewform" target='_blank'>
                  Googleフォーム
                </Link>
              </Td>
            </Tr>
            <Tr>
              <Td></Td><Td>•</Td>
            </Tr>
            <Tr>
              <Td>Powered by</Td>
              <Td>
                AirTable<br />
                Gatsby・Chakra UI<br />
                GitHub・Vercel<br />
              </Td>
            </Tr>
          </Tbody>
        </Table>        

        <Text align='center' fontSize='sm'>
          <Link href='https://eidantoei.org/' target='_blank' mx='2'>Eidantoei</Link>
          •
          <Link href='/' mx='2'>norasign.info</Link>
        </Text>
      </Box>
    </Box>
  )
}
  
export default FooterWithCredit