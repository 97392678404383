import * as React from 'react'
import { Box, Divider, Link, Text } from '@chakra-ui/react'

const FooterSimple = () => {
  return (
    <Box mt='20'>
      <Divider pt='200px' />
      <Box py='10' align='center'>
        <Text align='center' fontSize='sm'>
          <Link href='https://eidantoei.org/' target='_blank' mx='2'>Eidantoei</Link>
          •
          <Link href='/' mx='2'>norasign.info</Link>
        </Text>
      </Box>
    </Box>
  )  
}

export default FooterSimple