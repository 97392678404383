import * as React from 'react'
import { Box, Button, Text, Link, Flex, Spacer, Heading } from '@chakra-ui/react'
import { BiGridAlt } from "react-icons/bi";

const GlobalNav = () => {
  return (
    <Flex pt={['15px', '15px', '40px']} mb='10'>
      <Box verticalAlign='middle'>
        <Link href='/'
          _hover={{
            textDecoration: 'none',
          }}>
          <Heading as='h1' color='gray.800' size='md'>
            <Text>#野良サイン</Text>
            <Text fontWeight='normal'>norasign.info</Text>
          </Heading>
        </Link>
      </Box>
      <Spacer />
      <Box>
        <Link href='/archive/'
          _hover={{
            textDecoration: 'none',
          }}
        >
          <Button
            leftIcon={<BiGridAlt />} 
            colorScheme='yellow' size='lg' fontWeight='normal' borderRadius='none'
          >
            アーカイブ室
          </Button>
        </Link>
      </Box>
    </Flex>
  )
}

export default GlobalNav