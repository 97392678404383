import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Text } from '@chakra-ui/react'
import GlobalNav from '../components/global_nav'
import FooterSimple from '../components/footer_simple'
import FooterWithCredit from '../components/footer_with_credit'

const Layout = ({ pageTitle, showCredit, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let Footer = () => {
    if (showCredit) {
      return (<FooterWithCredit></FooterWithCredit>)
    } else {
      return (<FooterSimple></FooterSimple>)
    }
  }

  let title = data.site.siteMetadata.title
  if (pageTitle) {
    title = pageTitle + '｜' + title
  } 
  
  let HeadingPageTitle = () => {
    if (pageTitle) {
      return (
        <Text fontSize={{ base: '2xl', md: '5xl' }} py='10'>
          {pageTitle}
        </Text>
      )
    } else {
      return null
    }
  }

  return (
    <Container maxWidth='container.lg'>
      <title>{title}</title>
      <GlobalNav></GlobalNav>
      <main mb='50%'>
        <HeadingPageTitle></HeadingPageTitle>
        {children}
      </main>
      
      <footer>
        <Footer></Footer>
      </footer>
    </Container>
  )
}

export default Layout